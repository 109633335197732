import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Common/Loading";
import AddNewShowRoom from "../../Components/Showrooms/AddNewShowRoom";
import { getAllShowrooms } from "../../REDUX/actions/revendeur";

import ShowRoomList from "../../Components/Showrooms/ShowRoomList";
import { getPageByName } from "../../REDUX/actions/pages";
import PageCard from "../../Components/PageCard/PageCard";
import { pageNames } from "../../Api/EndPoints";

const ShowRoomsView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllShowrooms());
    dispatch(getPageByName(pageNames.magasins));
    // eslint-disable-next-line
  }, []);
  const { showroomsData, showRoomsDataLoading } = useSelector(
    (state) => state.showRoomsReducer
  );
  const { pagesLoading, ActivePage } = useSelector(
    (state) => state.pagesReducer
  );

  return showRoomsDataLoading ||
    pagesLoading ||
    !showroomsData ||
    !ActivePage ? (
    <Loading />
  ) : (
    <>
      <PageCard page={ActivePage} collapsed />
      <AddNewShowRoom /> <ShowRoomList />
    </>
  );
};

export default ShowRoomsView;
