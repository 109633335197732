import API from "../../Api/Api.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const getAllShowrooms = (filter) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      filters: {
        name: {
          $containsi: filter,
        },
      },
      populate: ["adress"],
      sort: ["createdAt:desc"],
      pagination: {
        page: 1,
        pageSize: 200,
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  dispatch({ type: "SHOWROOM_LOADING" });
  try {
    let { data } = await API.get("/api/revendeurs?" + query);

    dispatch({ type: "FETCH_SHOWROOMS", payload: data.data });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const getShowroomItem = (id, noLoading) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      filters: {
        id: {
          $eq: id,
        },
      },
      populate: {
        banner: {
          populate: "*",
        },
        photos_magasin: {
          populate: { cards: { populate: ["image", "image2"] } },
        },
        products: {
          populate: "image",
        },
        events: {
          populate: "name",
        },
        adress: {
          populate: "*",
        },
        seo: {
          populate: "ogimage",
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  if (!noLoading) {
    dispatch({ type: "SHOWROOM_LOADING" });
  }
  try {
    let { data } = await API.get("/api/revendeurs?" + query);

    dispatch({ type: "FETCH_SHOWROOM_ITEM", payload: data.data[0] });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const addShowroom = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  API.defaults.params = {};
  let defaultImage = null;

  dispatch({ type: "SHOWROOM_LOADING" });
  try {
    let image = await API.get("api/upload/files");
    defaultImage = image.data[0].id;
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }

  try {
    if (!defaultImage) {
      return dispatch(
        handleError("Importer au moin une image pour procéder", 1)
      );
    }
    const defaultBanner = {
      name: "Banniére revendeur ",
      title: "banniere par default",
      subtitle: "soustitre par default",
      image: [defaultImage],
      imageMobile: [defaultImage],
    };

    let bannerfr = await API.post("/api/sections", {
      data: defaultBanner,
    });

    let defaultCard = {
      name: "carte Image magasin",
      title: "titre image magasin par défault",

      image: defaultImage,
    };

    let cardFR = await API.post("/api/cards", {
      data: defaultCard,
    });

    let defaultListFR = {
      name: "Liste d'image magasin",
      cards: [cardFR.data.data.id],
      resolution: "carré",
    };

    let listFR = await API.post("/api/lists", {
      data: defaultListFR,
    });

    let defaultAdress = {
      fullAdress: "Numéro -- Rue --",
      postalCode: "*******",
      city: "Nice (par défaut)",
      country: "France (par défaut)",
      coordinates: [-0.370691110245275, 48.51969961538856],
    };

    let defaultShowRoom = {
      name: "Nom revendeur par défaut",
      phoneNumber: "** ** ** ** **",
      description: "Déscription revendeur par défaut",
      openTime: "Du **** au ***** de **h jusqu'a **h",
      email: "******@******.**",
      website: "www.******.com",
    };
    let defaultSEOFR = {
      title: "titre SEO article par defaut",
      description: "SEO description article par defaut",
      ogimage: defaultImage,
    };

    let SEOfr = await API.post("/api/seos", {
      data: defaultSEOFR,
    });

    let adressFr = await API.post("/api/adresses", {
      data: defaultAdress,
    });

    await API.post("/api/revendeurs", {
      data: {
        ...defaultShowRoom,
        adress: adressFr.data.data.id,
        banner: bannerfr.data.data.id,
        seo: SEOfr.data.data.id,
        photos_magasin: listFR.data.data.id,
      },
    });

    dispatch(handleInfo("Revendeur Ajouté", 1));
    dispatch(getAllShowrooms());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const deleteShowRoom = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  API.defaults.params = {};

  dispatch({ type: "SHOWROOM_LOADING" });
  try {
    await API.delete("/api/revendeurs/" + id);
    dispatch(handleInfo("Revendeur Supprimé", 1));
    dispatch(getAllShowrooms());
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const updateShowroom =
  (id, showroom, idAdress, adress) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;
    const query = qs.stringify({
      encodeValuesOnly: true,
    });

    dispatch({ type: "SHOWROOM_LOADING" });
    try {
      await API.put(`/api/adresses/${idAdress}?${query}`, {
        data: adress,
      });
      await API.put(`/api/revendeurs/${id}`, { data: showroom });

      dispatch(handleInfo("Revendeur Modifié", 1));
      dispatch(getShowroomItem(id));
    } catch (error) {
      dispatch(handleError("erreur réseau", 1));
      console.log(error);
    }
  };

export const changeProductsShowed =
  (id, exposedProducts) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;

    dispatch({ type: "SHOWROOM_LOADING" });
    try {
      await API.put(`/api/revendeurs/${id}`, {
        data: { products: exposedProducts },
      });

      dispatch(handleInfo("Revendeur Modifié", 1));
      dispatch(getShowroomItem(id));
    } catch (error) {
      dispatch(handleError("erreur réseau", 1));
      console.log(error);
    }
  };

export const changeAssociatedEvents =
  (id, associatedEvents) => async (dispatch) => {
    API.defaults.headers.common["Authorization"] = token;

    dispatch({ type: "SHOWROOM_LOADING" });
    try {
      await API.put(`/api/revendeurs/${id}`, {
        data: { events: associatedEvents },
      });

      dispatch(handleInfo("Revendeur Modifié", 1));
      dispatch(getShowroomItem(id));
    } catch (error) {
      dispatch(handleError("erreur réseau", 1));
      console.log(error);
    }
  };
