import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCard } from "../../REDUX/actions/lists";
import Loading from "../Common/Loading";
import ListCard from "./ListCard";
import "./Lists.css";
import _ from "lodash";
import { Spinner } from "react-bootstrap";

const Lists = ({ listData, fetchProduct, resolution, magasinId }) => {
  const dispatch = useDispatch();
  const [cardList, setcardList] = useState(listData?.attributes.cards.data);
  const [defaultElement, setdefaultElement] = useState(null);

  const { listLoading } = useSelector((state) => state.listsReducer);

  useEffect(() => {
    if (listData) {
      setcardList(listData.attributes.cards.data);
      const lastElement = cardList[cardList.length - 1];
      setdefaultElement({
        ..._.omit(listData.attributes.cards.data[0]?.attributes, [
          "locale",
          "image",
          "createdAt",
          "updatedAt",
          "list",
        ]),
        order: lastElement?.attributes.order + 1,
        image:
          listData.attributes.cards.data[0]?.attributes.image.data?.id || null,
        image2:
          listData.attributes.cards.data[0]?.attributes.image2.data?.id || null,
        imageMobile:
          listData.attributes.cards.data[0]?.attributes.image.data?.id || null,
        imageMobile2:
          listData.attributes.cards.data[0]?.attributes.image2.data?.id || null,
      });
    }
    // eslint-disable-next-line
  }, [listData]);
  const handleAddElement = () => {
    dispatch(addCard(defaultElement, listData.id, fetchProduct, magasinId));
  };

  return !cardList ? (
    <Loading />
  ) : (
    <div className="mt-5 row justify-content-center">
      <div className="text-orange mb-0 f20 text-start">
        Modifier {listData.attributes.name}{" "}
      </div>
      <div className=" mb-0 f16 fw-bold text-start">
        Contient {cardList.length} carte(s){" "}
      </div>
      <div
        className="AddToCarousel mb-4 bold f24 text-grey col-4"
        onClick={() => {
          handleAddElement();
        }}
      >
        <i className={`bi f48 bi-plus-square-fill iconPlus `}></i>
        {listLoading ? (
          <Spinner animation="border" variant="grey" />
        ) : (
          "Ajouter Un element à la liste"
        )}
      </div>

      <Splide options={{ perPage: 1, perMove: 1, width: "70vw" }}>
        {cardList.map((el, index) => (
          <SplideSlide className="container-fluid" key={index}>
            <ListCard
              cardData={el}
              listId={listData.id}
              noMoreDeletes={cardList.length === 1}
              magasinId={magasinId}
              fetchProduct={fetchProduct}
              resolution={
                resolution ? resolution : listData.attributes.resolution
              }
              listLoading={listLoading}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default Lists;
