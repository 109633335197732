export const sitePages = [
  {
    text: "Page d'accueil",
    value: "/",
  },
  {
    text: "Page Entreprise",
    value: "/entreprise",
  },
  {
    text: "Page Produits",
    value: "/produits",
  },
  {
    text: "Page Services",
    value: "/services",
  },
  {
    text: "Page Environnement",
    value: "/environnement",
  },
  {
    text: "Page Réalisation",
    value: "/realisation",
  },
  {
    text: "Page Mentions Légales",
    value: "/mention-legales",
  },
  {
    text: "Page Édition",
    value: "/produits/edition",
  },
  {
    text: "Page Papeterie",
    value: "/produits/papeterie",
  },
  {
    text: "Page Communication",
    value: "/produits/communication",
  },
  {
    text: "Page Événementiel",
    value: "/produits/evenementiel",
  },
  {
    text: "Page Hôtellerie et Restauration",
    value: "/produits/hotellerie-et-restauration",
  },
  {
    text: "Page Packaging",
    value: "/produits/packaging",
  },
  {
    text: "Page Impression",
    value: "/services/impression",
  },
  {
    text: "Page Dorure à Chaud",
    value: "/services/dorure-a-chaud",
  },
  {
    text: "Page Gaufrage",
    value: "/services/gaufrage",
  },
  {
    text: "Page Personnalisation",
    value: "/services/personnalisation",
  },
  {
    text: "Page Découpe",
    value: "/services/decoupe",
  },
];
