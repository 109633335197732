import API from "../../Api/Api.js";
import { handleError } from "./notifications.js";
const token = localStorage.getItem("token");

export const getNumbers = () => async (dispatch) => {
  dispatch({ type: "STATS_LOADING" });
  API.defaults.headers.common["Authorization"] = token;
  try {
    const pagesNumber = await API.get(`api/pages/`);
    const articleNumber = await API.get(`api/articles/`);
    const filesNumber = await API.get(`api/upload/files`);
    const productsNumber = await API.get(`api/products/`);

    dispatch({
      type: "GET_DB_STATS",
      payload: {
        pagesNumber: pagesNumber.data.meta.pagination.total,
        filesNumber: filesNumber.data.length,
        articleNumber: articleNumber.data.meta.pagination.total,
        productsNumber: productsNumber.data.meta.pagination.total,
      },
    });
  } catch (error) {
    dispatch(handleError("Erreur Réseau", 1));
    console.log(error);
  }
};
